import Vue from 'vue'
import Vuex from 'vuex'

import {moduleBookshelfScan} from './modules/bookshelfScan.js';
import {moduleFavorite} from "./modules/favorite.js";
import {moduleLibrary} from "./modules/library.js";
import {moduleSearch} from "./modules/search.js";
import {moduleItem} from "./modules/item.js";

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        bookshelfScan: moduleBookshelfScan,
        favorite: moduleFavorite,
        library: moduleLibrary,
        search: moduleSearch,
        item: moduleItem,
    }
})